<script>
import Vue from 'vue'
import * as action from '../../store/actions'
import Ellipsis from '@/components/partials/Ellipsis'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

let ModalNotify = Vue.component('modal-notify')
const CLICK_EXTRA = 2
const CLICK_DEFAULT = 1
const TYPE_FIRST = 1
const TYPE_SECOND = 2
const RECIPE_LEFT = 'LEFT'
const RECIPE_RIGHT = 'RIGHT'
let IMAGE_DEFAULT = ''

export default {
  props: [
    'menu',
    'mfirst',
    'msecond',
    'extraCheese',
    'itemId',
    'coupon',
    'onlyLunch'
  ],
  components: {
    Ellipsis
  },
  data: function() {
    return {
      choosedName: '',
      defaultsTopping: [],
      defaultsextraTopping: [],
      yourTopping: [],
      extraTopping: [],
      choosedFirst: {},
      choosedSecond: {},
      removeYourTopping: [],
      addExtraTopping: [],
      totalPrice: 0,
      totalExtraTopping: 0,
      transitionLeave: true,
      choosedCheese: [],
      gotCheese: { status: 'N', price: 0 },
      maxExtra: this.menu.extraToppingMax
        ? this.menu.extraToppingMax
        : this.$const.TOPPING.MAX_EXTRA,
      addExtraToppingWithPrice: [],
      disableAdd: true,
      t_c_desc: this.menu.t_and_c ? this.menu.t_and_c : '',
      showAccordionTC: false,
      choosedHalf: false,
      showExtra: false,
      buttonAdd: {
        text: '',
        visible: false,
        active: false
      },

      // DUCPN
      menuList: null,
      showLeftHalf: false,
      showRightHalf: false,
      showLeftHalfRecipe: false,
      showLeftHalfTopping: false,
      showRightHalfRecipe: false,
      showRightHalfTopping: false,
      firstLayers: [],
      secondLayers: [],
      recipeList: [],
      step: 1,
      leftToppingList: [],
      rightToppingList: [],
      selectedFirstLayer: null,
      selectedSecondLayer: null,
      selectedLeftRecipe: null,
      selectedRightRecipe: null,
      selectedLeftTopping: [],
      selectedRightTopping: [],
      removedLeftToppingList: [],
      removedRightToppingList: [],
      addLeftToppingList: [],
      addRightToppingList: [],
      showLeftToppingPlus: false,
      showLeftToppingMinus: false,
      showRightToppingPlus: false,
      showRightToppingMinus: false,
      headerName: '',
      headerDescription: '',
      image: '',
      RECIPE_LEFT: 'LEFT',
      RECIPE_RIGHT: 'RIGHT',
      IMAGE_DEFAULT: IMAGE_DEFAULT
    }
  },
  computed: {
    ...mapGetters({
      selectedCategory: 'selectedCategory',
      selectedSubCategory: 'selectedSubCategory'
    })
  },
  watch: {
    choosedCheese(val) {
      this.updateTotalPrice()
      if (
        parseInt(this.addExtraTopping.length) + parseInt(val.length) >=
          this.maxExtra
      ) {
        this.showAllExtraTopping(true)
      } else {
        this.showAllExtraTopping(false)
      }
    },
    step: function(val) {
      if (val === 1) {
        this.showLeftHalf = false

        this.showRightHalfRecipe = true
        this.showRightHalfTopping = false
      } else {
        this.showLeftHalf = true
        this.showLeftHalfRecipe = true
        this.showLeftHalfTopping = false
      }
    },
    totalPrice: function(val) {
      this.checkSubmit()
    },
    selectedLeftRecipe: function(val, old) {
      this.setButtonAdd()
    },
    selectedRightRecipe: function(val, old) {
      this.setButtonAdd()
    },
    showLeftHalf: function(val, old) {
      this.setButtonAdd()

      this.showRightHalf = !val
    },
    showRightHalf: function(val, old) {
      if (!this.showRightHalfRecipe && !this.showRightHalfTopping) {
        this.showRightHalfRecipe = true
      }

      this.setButtonAdd()
    }
  },
  mounted() {
    this.$cart.init(this)
    this.getLayer()
    this.setDefaultTextAndImage()
    let data = this._.cloneDeep(this.menu)
    if (this.onlyLunch) {
      data.uuid = this.$const.HALF_HALF_LUNCH_UUID
      data.name = this.$t('half_lunch_name')
    } else {
      data.uuid = this.$const.HALF_HALF_UUID
      data.name = this.$t('btn_hatf_halt')
    }
    this.eventTrackingProductDetail(this, data, this.$const.CARD_ITEM_TYPE.HALF)
  },
  destroyed() {
    let sub = this.$route.params.sub
    let uuid = this.$route.params.uuid
    let slug = this.$route.params.slug

    document.body.classList.remove('modal-open')
    this.setSelectedCart(null)

    if (sub === this.$const.HEADER_MENU.HALF_HAlF) {
      this.redirectToMenuPage(slug, null, null, true)
    } else if (uuid === this.$const.HEADER_MENU.HALF_HAlF) {
      this.redirectToMenuPage(slug, sub, null, true)
    }
  },
  methods: {
    ...mapActions({
      setSelectedCart: action.SET_SELECTED_CART
    }),
    // HALF & HALF
    setDefaultTextAndImage() {
      if (!this.selectedLeftRecipe && !this.selectedRightRecipe) {
        if (this.onlyLunch === this.$const.HALF_HALF_SET.LUNCH) {
          this.headerName = this.$t('half_lunch_name')
          this.headerDescription = this.$t('half_lunch_description')
          this.image = this.$const.HALF_HALF_IMAGES.LUNCH_4
        } else if (this.onlyLunch === this.$const.HALF_HALF_SET.NORMAL) {
          this.headerName = this.$t('btn_hatf_halt')
          this.headerDescription = this.$t('half_description')
          this.image = this.$const.HALF_HALF_IMAGES.NORMAL_4
        }
      }
    },
    setButtonAdd() {
      this.buttonAdd.text = this.$t('half_btn_choose_right')
      this.buttonAdd.active = false
      this.buttonAdd.visible = false

      if (
        (this.showLeftHalf && !this.selectedLeftRecipe) ||
          (this.showRightHalf && !this.selectedRightRecipe)
      ) {
        this.buttonAdd.visible = false
      } else if (
        this.showLeftHalf &&
          this.selectedLeftRecipe &&
          !this.showRightHalf &&
          !this.selectedRightRecipe
      ) {
        this.buttonAdd.text = this.$t('half_btn_choose_right')
        this.buttonAdd.active = true
        this.buttonAdd.visible = true
      } else if (
        this.selectedFirstLayer &&
          this.selectedSecondLayer &&
          this.selectedLeftRecipe &&
          this.selectedRightRecipe
      ) {
        this.buttonAdd.text = this.$t('add_to_my_basket')
        this.buttonAdd.active = true
        this.buttonAdd.visible = true
      }
    },
    clickLeftHalf() {
      this.showLeftHalf = true
      this.showRightHalf = false
    },
    clickRightHalf() {
      if (this.selectedLeftRecipe) {
        this.showLeftHalf = false
        this.showRightHalf = true
      } else {
        this.$modal.show(
          ModalNotify,
          {
            name: 'modal-notify',
            title: this.$t('message'),
            message: this.$t('half_choose_left')
          },
          {
            class: 'modal-menu',
            transition: 'nice-modal-fade',
            overlayTransition: 'overlay-fade',
            clickToClose: false,
            name: 'modal-notify'
          }
        )
      }
    },
    getLayer() {
      let vm = this

      let orderTime = this.convertDateTimeFromTimezoneToUTC(
        this.$disposition.getByKey(this, 'timezone', ''),
        this.$disposition.getByKey(this, 'collectionTimeValue', '')
      )
      if (!orderTime) {
        orderTime = moment()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      }

      let data = {
        order_time: orderTime,
        outlet_code: this.$disposition.getByKey(this, 'outletCode', null),
        category_id: this.$store.getters.category_uuid,
        tier: this.$disposition.getByKey(this, 'tier', '1'),
        order_type: this.$disposition.getByKey(this, 'orderType', 'D').charAt(0)
      }

      vm.axios.getHalf(
        TYPE_FIRST,
        data,
        function(res_first) {
          vm.firstLayers = res_first.data.data.items

          vm.axios.getHalf(
            TYPE_SECOND,
            data,
            function(res_second) {
              vm.secondLayers = res_second.data.data.items
            },
            function(err) {
              console.log(err)
            }
          )
        },
        function(err) {
          console.log(err)
        }
      )
    },
    getRecipePrice(selectedRecipe) {
      const vm = this
      let price = 0

      if (vm.recipeList && vm.recipeList.length) {
        const recipe = vm.recipeList.find(
          recipe => selectedRecipe.sku === recipe.sku
        )
        if (recipe) {
          price += recipe.price
          if (recipe.first_layers && recipe.first_layers.length) {
            const first = recipe.first_layers.find(
              first => first.first_id === vm.selectedFirstLayer.id
            )
            if (first) {
              price += first.price
              if (first.second_layers && first.second_layers.length) {
                const second = first.second_layers.find(
                  second => second.second_id === vm.selectedSecondLayer.id
                )
                price =
                  second.second_id === vm.selectedSecondLayer.id
                  ? price + second.price
                  : price
              }
            }
          }
        }
      }

      return this.formatNum(price / 2)
    },
    getPriceTopping(topping) {
      let price = 0
      if (
        topping &&
          topping.prices &&
          this.selectedFirstLayer &&
          this.selectedFirstLayer.sku
      ) {
        price = topping.prices[this.selectedFirstLayer.sku]
          ? topping.prices[this.selectedFirstLayer.sku]
          : 0
      } else {
        price = 0
      }

      if (price > 0) {
        price = parseInt(this.formatNum(price / 2))
      }

      return price
    },
    selectLayer(first, second) {
      var vm = this

      this.selectedFirstLayer = first
      this.selectedSecondLayer = second
      // this.buttonAdd.active = true
      // this.disableAdd = true;

      let orderTime = this.convertDateTimeFromTimezoneToUTC(
        this.$disposition.getByKey(this, 'timezone', ''),
        this.$disposition.getByKey(this, 'collectionTimeValue', '')
      )
      if (!orderTime) {
        orderTime = moment()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      }

      let data = {
        order_time: orderTime,
        outlet_code: this.$disposition.getByKey(this, 'outletCode', null),
        order_type: this.$disposition
          .getByKey(this, 'orderType', 'D')
          .charAt(0),
        half_half: 1,
        // first_layer_sku: first.sku,
        // second_layer_sku: second.sku,
        first_layer_id: first.id,
        second_layer_id: second.id,
        // 'only_lunch': vm.onlyLunch != null ? vm.onlyLunch : null
        only_lunch: null
      }

      vm.axios.getMenuByCategory(
        data,
        function(res_first) {
          vm.recipeList = res_first.data.data.items
          vm.step = 2
        },
        function(err) {
          console.log(err)
        }
      )
    },
    selectRecipe(selectedRecipe, order) {
      let vm = this

      let topping = []
      if (selectedRecipe.toppings) {
        topping = JSON.parse(JSON.stringify(selectedRecipe.toppings))
      }

      if (order === vm.RECIPE_LEFT) {
        vm.selectedLeftRecipe = selectedRecipe
        vm.leftToppingList = topping
        for (let t in vm.leftToppingList.default) {
          vm.leftToppingList.default[t].default = true
          vm.leftToppingList.default[t].isDisable = false
        }
        for (let t in vm.leftToppingList.extra) {
          vm.leftToppingList.extra[t].default = false
          if (vm.maxExtra === 0) {
            vm.leftToppingList.extra[t].isDisable = true
          } else {
            vm.leftToppingList.extra[t].isDisable = false
          }
        }
        vm.showLeftHalf = true
        vm.showLeftHalfRecipe = false
        vm.showLeftHalfTopping = true
      } else {
        vm.selectedRightRecipe = selectedRecipe
        vm.rightToppingList = topping
        for (let t in vm.rightToppingList.default) {
          vm.rightToppingList.default[t].default = true
          vm.rightToppingList.default[t].isDisable = false
        }
        vm.showRightHalfRecipe = false
        vm.showRightHalfTopping = true
      }
      if (vm.selectedLeftRecipe && vm.selectedRightRecipe) {
        let dataObject = { uuid: vm.$const.HALF_HALF_UUID }
        if (vm.onlyLunch) {
          dataObject.uuid = vm.$const.HALF_HALF_LUNCH_UUID
        }
        this.eventTrackingProductView(null, dataObject)
        this.evenTrackingProductClick(
          this,
          dataObject,
          'product-selection-from-variant-list'
        )
      }
      this.updatePrice()
    },
    changeRecipe(order) {
      if (order === this.RECIPE_LEFT) {
        this.showLeftHalf = true
        this.showLeftHalfRecipe = true
        this.showLeftHalfTopping = false

        this.selectedLeftRecipe = null
        this.selectedLeftTopping = null
        this.addLeftToppingList = []
        this.removedLeftToppingList = []
        this.leftToppingList = []
      } else {
        this.showRightHalf = true
        this.showRightHalfRecipe = true
        this.showRightHalfTopping = false

        this.selectedRightRecipe = null
        this.selectedRightTopping = null
        this.addRightToppingList = []
        this.removedRightToppingList = []
        this.rightToppingList = []
      }

      this.updatePrice()
      this.setDefaultTextAndImage()
    },
    changeLayer() {
      this.step = 1
      this.selectedLeftRecipe = null
      this.selectedRightRecipe = null
      this.selectedLeftTopping = null
      this.selectedRightTopping = null

      this.addLeftToppingList = []
      this.removedLeftToppingList = []
      this.addRightToppingList = []
      this.removedRightToppingList = []

      this.updatePrice()
    },
    changeTopping(topping, type, index, order) {
      let vm = this
      let newTopping = JSON.parse(JSON.stringify(topping))
      let addToppingList = null
      let removeToppingList = null
      let selectedRecipe = null
      let toppingList = null

      if (order === this.RECIPE_LEFT) {
        toppingList = this.leftToppingList
        addToppingList = this.addLeftToppingList
        removeToppingList = this.removedLeftToppingList
        selectedRecipe = this.selectedLeftRecipe
      } else if (order === this.RECIPE_RIGHT) {
        toppingList = this.rightToppingList
        addToppingList = this.addRightToppingList
        removeToppingList = this.removedRightToppingList
        selectedRecipe = this.selectedRightRecipe
      }

      if (type === CLICK_DEFAULT) {
        toppingList.default.splice(index, 1)

        if (topping.type === vm.$const.TOPPING.TYPE_EXTRA) {
          for (let index in addToppingList) {
            if (addToppingList[index].id === topping.id) {
              addToppingList.splice(index, 1)
              break
            }
          }
        } else {
          toppingList.extra.unshift(newTopping)
          removeToppingList.push(newTopping)
        }

        this.eventTrackingVariantUnSelect({
          item: selectedRecipe,
          topping: newTopping,
          first: this.selectedFirstLayer
        })
      } else if (type === CLICK_EXTRA) {
        if (!topping.isDisable) {
          if (topping.default) {
            toppingList.extra.splice(index, 1)
          }

          if (newTopping.default) {
            toppingList.default.push(newTopping)
          } else {
            toppingList.default.unshift(newTopping)
          }
          if (topping.type === vm.$const.TOPPING.TYPE_EXTRA || topping.type === vm.$const.TOPPING.TYPE_BOTH) {
            addToppingList.push(newTopping)
          } else {
            for (let index in removeToppingList) {
              if (removeToppingList[index].id === topping.id) {
                removeToppingList.splice(index, 1)
                break
              }
            }
          }

          this.eventTrackingVariantSelect({
            item: selectedRecipe,
            topping: newTopping,
            first: this.selectedFirstLayer
          })
        }
      }

      this.setActiveToppingList(toppingList.extra, toppingList.default)
      this.updatePrice()
    },
    updatePrice() {
      var total = 0
      var price = 0
      if (this.selectedLeftRecipe) {
        total += parseInt(this.getRecipePrice(this.selectedLeftRecipe))
      }

      if (this.selectedRightRecipe) {
        total += parseInt(this.getRecipePrice(this.selectedRightRecipe))
      }

      if (this.addLeftToppingList && this.addLeftToppingList.length) {
        for (var i = 0; i < this.addLeftToppingList.length; i++) {
          price = parseInt(this.getPriceTopping(this.addLeftToppingList[i]))
          total += price
        }
      }

      if (this.addRightToppingList && this.addRightToppingList.length) {
        for (var i = 0; i < this.addRightToppingList.length; i++) {
          total += this.getPriceTopping(this.addRightToppingList[i])
        }
      }

      this.totalPrice = total
    },
    checkDisableExtra(uuid) {
      let exist = false
      for (let i in this.choosedCheese) {
        if (uuid === this.choosedCheese[i].uuid) {
          exist = true
        }
      }
      if (
        !exist &&
        parseInt(this.addExtraTopping.length) +
          parseInt(this.choosedCheese.length) >=
          this.maxExtra
      ) {
        return true
      }

      if (
        exist &&
        parseInt(this.addExtraTopping.length) +
          parseInt(this.choosedCheese.length) <
          this.maxExtra
      ) {
        return false
      }

      return false
    },
    /**
     * Get cheese price belong size pizza
     * */
    getCheese(size) {
      let price = 0
      for (let cheese in this.extracheese) {
        if (this.extracheese[cheese].Size === this.convertString(size)) {
          price = this.extracheese[cheese].Price
        }
      }
      return price
    },
    getLayerFinal(selectedRecipe) {
      var result = {
        first: null,
        second: null
      }

      let vm = this

      if (
        selectedRecipe &&
        selectedRecipe.first_layers &&
        selectedRecipe.first_layers.length
      ) {
        const tempFirst = selectedRecipe.first_layers.find(
          x => x.first_id === vm.selectedFirstLayer.id
        )
        if (tempFirst) {
          result.first = tempFirst
          const tempSecond = tempFirst.second_layers.find(
            x => x.second_id === vm.selectedSecondLayer.id
          )
          if (tempSecond) result.second = tempSecond
        }
      }
      return result
    },
    /**
     * Adding item to cart
     * Prepare new format to API
     * */
    checkSubmit() {
      if (
        !this.selectedFirstLayer ||
        !this.selectedSecondLayer ||
        !this.selectedLeftRecipe ||
        !this.selectedRightRecipe
      ) {
        // this.buttonAdd.active = true
        // this.disableAdd = true;
        return false
      }
      // this.buttonAdd.active = false
      // this.disableAdd = false;
      return true
    },
    addToBasket() {
      if (!this.selectedRightRecipe) {
        this.showRightHalf = true
        this.showLeftHalf = false
        this.buttonAdd.visible = false
        this.buttonAdd.active = false
        return
      }

      if (this.checkSubmit()) {
        let item = {
          group: {},
          selling_start: this.menu.selling_start,
          products: []
        }
        if (this.coupon) {
          item.group.coupon_code = this.coupon
        }
        item.group['outlet_code'] = this.getOutletCode(this)
        item.group['tier'] = this.$cart.tier
        item.group['order_type'] = this.$cart.order_type
        item.group['order_time'] = this.getTimePickerDate(this)
        item.group['product_type'] = this.$const.HALF_HALF_ID
        item.group['combo_id'] = null
        item.group['quantity'] = 1
        item.group['category_uuid'] = this.$store.getters.category_uuid
        var layer1 = this.getLayerFinal(this.selectedLeftRecipe)
        var layer2 = this.getLayerFinal(this.selectedRightRecipe)

        let product = {}
        product.category_id = this.$store.getters.category_uuid
        product.combo_group_id = ''
        product.product_id = this.selectedLeftRecipe.uuid
        product.first_id = layer1.first.uuid
        product.second_id = layer1.second.uuid
        product.toppings = {}
        product.toppings.remove = this.$cart.getToppingById(
          this.removedLeftToppingList
        )
        let newExtraTopping = this.lodash.union(this.addLeftToppingList, {})
        product.toppings.extra = this.$cart.getToppingById(newExtraTopping)
        item.products.push(product)

        let product2 = {}
        product2.category_id = this.$store.getters.category_uuid
        product2.combo_group_id = ''
        product2.product_id = this.selectedRightRecipe.uuid
        product2.first_id = layer2.first.uuid
        product2.second_id = layer2.second.uuid
        product2.toppings = {}
        product2.toppings.remove = this.$cart.getToppingById(
          this.removedRightToppingList
        )
        let newExtraTopping2 = this.lodash.union(this.addRightToppingList, {})
        product2.toppings.extra = this.$cart.getToppingById(newExtraTopping2)
        item.products.push(product2)
        let pro = {
          items: [],
          id: this.$const.HALF_HALF_UUID,
          name: this.$t('btn_hatf_halt'),
          price: this.totalPrice
        }
        if (this.onlyLunch) {
          pro.id = this.$const.HALF_HALF_LUNCH_UUID
          pro.name = this.$t('half_lunch_name')
        }

        pro.items.push({
          item: this.selectedLeftRecipe,
          first: layer1.first,
          second: layer1.second
        })
        pro.items.push({
          item: this.selectedRightRecipe,
          first: layer2.first,
          second: layer2.second
        })
        this.eventTrackingAddToCart(
          this,
          pro,
          'main',
          this.$const.CARD_ITEM_TYPE.HALF
        )
        this.$cart.insert(this, item)

        this.$emit('close')
      }
    }
  }
}
</script>

<template>
  <div id="modal-menu" class="modal-half">
    <div class="modal-content">
      <button
        type="button"
        class="close close-left"
        data-dismiss="modal"
        aria-label="Close"
        @click="$emit('close')"
      >
        <span aria-hidden="true">×</span>
      </button>

      <form id="form-add-menu" method="post" action="">
        <div class="image-cover sides">
          <!--v-if="selectedRightRecipe && selectedRightRecipe.image"-->
          <img
            class="image"
            v-show="selectedLeftRecipe != null"
            v-bind:src="
              selectedLeftRecipe ? selectedLeftRecipe.image.desktop_detail : ''
            "
          />
          <img
            class="image"
            v-show="selectedRightRecipe != null"
            v-bind:src="
              selectedRightRecipe
                ? selectedRightRecipe.image.desktop_detail
                : ''
            "
          />
          <img
            class="menu-icon"
            v-bind:class="
              selectedLeftRecipe || selectedRightRecipe ? 'has-image' : ''
            "
            v-bind:src="image"
          />
        </div>
        <div class="modal-body display-flex flex-column">
          <div class="modal-head">
            <h1 class="t22 fw600 title-name">{{ headerName }}</h1>

            <p
              class="t13 fw300 pr10 pt10 text-black des"
              style=""
              v-if="headerDescription"
            >
              <ellipsis
                :data="headerDescription"
                :line-clamp="3"
                :line-height="'24px'"
              ></ellipsis>
            </p>

            <div
              role="button"
              @click="showAccordionTC = !showAccordionTC"
              v-if="t_c_desc"
            >
              <h6 class="panel-title t14 fw600">
                <i class="fa fa-caret-down" v-if="showAccordionTC"></i>
                <i class="fa fa-caret-up" v-if="!showAccordionTC"></i>
                <span class="pl-5">{{ $t('t_and_c') }}</span>
              </h6>
            </div>
            <collapse v-model="showAccordionTC" v-if="t_c_desc">
              <div class="menu-description" style="padding: 10px 0">
                <nl2br tag="p" :text="t_c_desc" />
              </div>
            </collapse>
          </div>
          <div
            class="flex1auto selector-container"
            style="max-height: calc(100vh - 515px);"
          >
            <!-- SIZE & CRUST -->
            <transition name="fade">
              <div class="box-select-variant-addon" v-if="step === 1">
                <p
                  class="fw600 t15 mb15 text-black bg-grey-lightest md:bg-transparent p10 "
                  v-if="firstLayers && firstLayers.length > 0"
                >
                  {{ $t('half_select_crust') }}
                </p>

                <!-- FIRST LAYER -->
                <div v-for="first in firstLayers" :key="genUniqueKey(first)">
                  <div
                    class="t15 fw600 heading-line-right mt10 mb10 text-black p10 bg-white md:bg-transparent"
                  >
                    {{ first.name }}
                  </div>

                  <!-- SECOND LAYER -->
                  <div
                    v-for="second in secondLayers"
                    :key="genUniqueKey(second)"
                    @click="selectLayer(first, second)"
                    class="variant-addon-item option display-flex t15 p10 text-black bg-grey-lightestest md:bg-transparent active"
                  >
                    <div
                      class="flex1 display-flex items-center justify-start text-black fw400"
                    >
                      <div>
                        <img
                          v-if="second.image"
                          width="35"
                          v-bind:alt="second.name"
                          v-bind:src="second.image.desktop_detail"
                          class="option---icon"
                        />
                      </div>
                      <div class="pl-10 option---content ">
                        <div>{{ second.name }}</div>
                        <div class="option__long-description t13 fw400"></div>
                      </div>
                    </div>
                  </div>
                  <!-- END SECOND LAYER -->
                </div>
                <!-- END FIRST LAYER -->
              </div>
            </transition>
            <!-- END SIZE & CRUST -->

            <!-- RECIPE & TOPPING -->
            <transition name="fade">
              <div
                id="recipe-list"
                class="box-select-variant-addon"
                v-if="step === 2"
              >
                <p
                  class="fw600 t15 mb15 text-black bg-grey-lightest md:bg-transparent p10 "
                  v-if="showLeftHalf && showLeftHalfRecipe"
                >
                  <span>{{ $t('half_left_recipe') }}</span>
                </p>

                <p
                  class="fw600 t15 mb15 text-black bg-grey-lightest md:bg-transparent p10 "
                  v-if="showRightHalf && showRightHalfRecipe"
                >
                  <span>{{ $t('half_right_recipe') }}</span>
                </p>

                <p
                  class="fw600 t15 mb15 text-black bg-grey-lightest md:bg-transparent p10 "
                  v-if="showLeftHalf && showLeftHalfTopping"
                >
                  <span>{{ $t('half_left_topping') }}</span>
                </p>

                <p
                  class="fw600 t15 mb15 text-black bg-grey-lightest md:bg-transparent p10 "
                  v-if="showRightHalf && showRightHalfTopping"
                >
                  <span>{{ $t('half_right_topping') }}</span>
                </p>

                <div class="variant-addon-choosen p10">
                  <div class="display-flex t15">
                    <span class="flex1 name fw400  text-black">{{
                      convertString(selectedSecondLayer.name)
                    }}</span>
                    <a
                      @click="changeLayer"
                      class="cursor-pointer cl-78838c border-b t13"
                      >{{ $t('button_change') }}</a
                    >
                  </div>
                </div>

                <div class="xs-flex text-glitch-fix tab-nav">
                  <div
                    class="tab tab-left xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-10 bold no-outline xs-nowrap text-glitch-fix "
                    role="button"
                    style="margin-right: 2px; font-size: 16px;"
                    tabindex="0"
                    v-bind:class="showLeftHalf ? 'active' : ''"
                    @click="clickLeftHalf"
                  >
                    <span>{{ $t('left_half') }}:</span>
                  </div>
                  <div
                    class="tab tab-right xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-10 bold no-outline xs-nowrap text-glitch-fix"
                    role="button"
                    style="font-size: 16px;"
                    tabindex="0"
                    v-bind:class="showRightHalf ? 'active' : ''"
                    @click="clickRightHalf"
                  >
                    <span>{{ $t('right_half') }}:</span>
                  </div>
                </div>

                <div class="tab-content">
                  <!-- LEFT HALF -->
                  <div
                    role="tabpanel"
                    class="tab-pane"
                    v-bind:class="showLeftHalf ? 'active' : ''"
                  >
                    <div class="top-list">
                      <div
                        class="add-toppings-str pl-10"
                        v-if="addLeftToppingList && addLeftToppingList.length"
                      >
                        <div class="text-green">
                          <strong>
                            {{ $t('cart.add') }}:
                            {{
                              convertString(
                                addLeftToppingList
                                  .map(function(f) {
                                    return f.name
                                  })
                                  .join(', ')
                              )
                            }}
                          </strong>
                        </div>
                      </div>
                      <div
                        class="remove-toppings-str pl-10"
                        v-if="
                          removedLeftToppingList &&
                            removedLeftToppingList.length
                        "
                      >
                        <div class="text-orange">
                          <strong>
                            {{ $t('cart.remove') }}:
                            {{
                              convertString(
                                removedLeftToppingList
                                  .map(function(f) {
                                    return f.name
                                  })
                                  .join(', ')
                              )
                            }}
                          </strong>
                        </div>
                      </div>
                    </div>

                    <div v-if="recipeList && recipeList.length">
                      <!-- RECIPE -->
                      <div v-show="showLeftHalf && showLeftHalfRecipe">
                        <div
                          v-for="item in recipeList"
                          :key="genUniqueKey(item)"
                          @click="selectRecipe(item, RECIPE_LEFT)"
                          class="variant-addon-item option display-flex t15 p10 text-black bg-grey-lightestest md:bg-transparent active"
                        >
                          <div
                            class="left flex1 display-flex items-center justify-start text-black fw400"
                          >
                            <img
                              class="option---icon"
                              v-if="item.image"
                              v-bind:alt="item.name"
                              v-bind:src="item.image.desktop_thumbnail"
                            />
                            <div class="pl-10 option---content ">
                              <div>{{ item.name }}</div>
                              <div class="option__long-description t13 fw400">
                                {{ item.main_description }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="price fw600 text-right pl10 pr10 flex-center"
                          >
                            {{
                              $t('currency_display', {
                                currency: $const.CURRENCY,
                                amount: formatNumDisplay(getRecipePrice(item))
                              })
                            }}
                          </div>
                        </div>
                      </div>
                      <!-- END RECIPE -->

                      <!-- TOPPING -->
                      <div v-show="showLeftHalf && showLeftHalfTopping">
                        <div class="variant-addon-choosen p10">
                          <div class="display-flex t15">
                            <span
                              class="flex1 name fw400 text-black"
                              v-if="selectedLeftRecipe"
                            >
                              {{ selectedLeftRecipe.name }}
                            </span>
                            <a
                              @click="changeRecipe(RECIPE_LEFT)"
                              class="cursor-pointer cl-78838c border-b t13"
                              >{{ $t('button_change') }}</a
                            >
                          </div>
                        </div>

                        <div class="p10 box-topping-contain">
                          <div class="menu-toppings  ">
                            <div
                              class="topping-header cheese text-black "
                              style=""
                              v-if="showExtra"
                            >
                              {{ $t('change_cheese') }}
                            </div>
                            <div
                              class="extra-cheese option option-cheese bg-grey-lightestest md:bg-transparent"
                              v-bind:class="{
                                'opacity-3': checkDisableExtra(topping.uuid)
                              }"
                              style=""
                              v-for="(topping, index) in extraTopping"
                              v-if="topping.special === '1'"
                              :key="RECIPE_LEFT + genUniqueKey(topping)"
                            >
                              <div class="xs-mt-20">
                                <div class="extra-cheese-option" style="">
                                  <label
                                    class="xs-items-center xs-px-10 rounded label-radio"
                                    :for="topping.uuid"
                                  >
                                    <input
                                      class="xs-block rounded xs-p-5 radio ExtraCheese"
                                      :id="topping.uuid"
                                      name="ExtraCheese"
                                      :value="topping"
                                      v-model="choosedCheese"
                                      type="checkbox"
                                      :disabled="
                                        checkDisableExtra(topping.uuid)
                                      "
                                    />
                                    <i class="pull-left xs-my-15"></i>
                                    <span
                                      class="xs-mr-auto typo-l5 xs-items-center xs-py-15"
                                    >
                                      <i class="xs-hide md-block xs-mr-10"></i>
                                      <span class="fw400 text-black t15">{{
                                        topping.name
                                      }}</span>
                                    </span>
                                    <span
                                      class="pull-right extra-cheese-price t15 fw600 cl-525252"
                                      >+ {{ $const.CURRENCY }}
                                      {{
                                        formatNumDisplay(
                                          getPriceTopping(topping)
                                        )
                                      }}
                                    </span>
                                  </label>
                                </div>
                                <div class="xs-mb-10"></div>
                              </div>
                            </div>

                            <div
                              class="topping-header"
                              style=""
                              v-if="
                                leftToppingList &&
                                  leftToppingList.extra &&
                                  leftToppingList.extra.length
                              "
                              @click="
                                showLeftToppingPlus = !showLeftToppingPlus
                              "
                              :class="{ active: showLeftToppingPlus }"
                            >
                              {{ $t('add_toppings') }}
                              <i data-v-38def44e="" class="fa fa-caret-up"></i>
                            </div>
                            <collapse v-model="showLeftToppingPlus">
                              <div
                                class="add-toppings text-black"
                                style=""
                                v-if="
                                  leftToppingList &&
                                    leftToppingList.extra &&
                                    leftToppingList.extra.length
                                "
                              >
                                <div
                                  v-for="(extopping,
                                  index) in leftToppingList.extra"
                                  :key="RECIPE_LEFT + genUniqueKey(extopping)"
                                  v-if="extopping.special != '1'"
                                  class="add-toppings-item display-flex t15 p10 cursor-pointer option bg-grey-lightestest md:bg-transparent"
                                  v-bind:class="{
                                    disable: extopping.isDisable
                                  }"
                                  @click="
                                    changeTopping(
                                      extopping,
                                      2,
                                      index,
                                      RECIPE_LEFT
                                    )
                                  "
                                >
                                  <div class="flex1 fw400 ">
                                    <img
                                      v-bind:src="extopping.ImageURL"
                                      width="24"
                                      height="24"
                                    />
                                    <span class="pl-10">{{
                                      convertString(extopping.name)
                                    }}</span>
                                  </div>
                                  <div
                                    class="text-right pl10 pr10 topping-price fw600 cl-525252"
                                    style=""
                                    v-if="
                                      !extopping.default &&
                                        getPriceTopping(extopping)
                                    "
                                  >
                                    +
                                    {{
                                      $t('currency_display', {
                                        currency: $const.CURRENCY,
                                        amount: formatNumDisplay(
                                          getPriceTopping(extopping)
                                        )
                                      })
                                    }}
                                  </div>
                                  <div
                                    class="add-topping-btn bold text-right pl10 pr10"
                                  >
                                    <div
                                      class="add-icon mr-0 ml-5 fa-plus-icon "
                                    >
                                      <i class="add-icon__v"></i>
                                      <i class="add-icon__h"></i>
                                    </div>
                                    <img
                                      src="https://static.phdvasia.com/mx/icons/remove.svg"
                                      class="cursor-pointer fa-remove-image"
                                      alt="remove"
                                      style="display: none"
                                    />
                                  </div>
                                </div>
                              </div>
                            </collapse>

                            <div
                              class="topping-header"
                              style=""
                              v-if="leftToppingList && leftToppingList.default"
                              @click="
                                showLeftToppingMinus = !showLeftToppingMinus
                              "
                              :class="{ active: showLeftToppingMinus }"
                            >
                              {{ $t('your_toppings') }}
                              <i data-v-38def44e="" class="fa fa-caret-up"></i>
                            </div>
                            <collapse v-model="showLeftToppingMinus">
                              <div
                                class="your-toppings text-black"
                                style=""
                                v-if="
                                  leftToppingList &&
                                    leftToppingList.default &&
                                    leftToppingList.default.length
                                "
                              >
                                <div
                                  class="your-toppings-item display-flex t15 p10 cursor-pointer option visible"
                                  style=""
                                  v-for="(topping,
                                  index) in leftToppingList.default"
                                  :key="RECIPE_LEFT + genUniqueKey(topping)"
                                  @click="
                                    changeTopping(
                                      topping,
                                      1,
                                      index,
                                      RECIPE_LEFT
                                    )
                                  "
                                >
                                  <div class="flex1 fw400">
                                    <img
                                      v-bind:src="topping.ImageURL"
                                      width="24"
                                      height="2"
                                    />
                                    <span class="pl-10">{{
                                      convertString(topping.name)
                                    }}</span>
                                  </div>
                                  <div
                                    class=" text-right pl10 pr10 topping-price fw600 cl-525252"
                                    v-if="
                                      !topping.default &&
                                        getPriceTopping(topping)
                                    "
                                  >
                                    +
                                    {{
                                      $t('currency_display', {
                                        currency: $const.CURRENCY,
                                        amount: formatNumDisplay(
                                          getPriceTopping(topping)
                                        )
                                      })
                                    }}
                                  </div>
                                  <div
                                    class="add-topping-btn bold text-right pl10 pr10"
                                  >
                                    <img
                                      src="https://static.phdvasia.com/mx/icons/remove.svg"
                                      class="cursor-pointer"
                                      alt="remove"
                                    />
                                  </div>
                                </div>
                              </div>
                            </collapse>
                          </div>
                        </div>
                      </div>
                      <!-- END TOPPING -->
                    </div>
                    <div
                      v-else
                      class="empty_recipe"
                      v-html="
                        $t('message_empty_menu', {
                          first_layer: selectedFirstLayer.name,
                          second_layer: selectedSecondLayer.name
                        })
                      "
                    ></div>
                  </div>
                  <!-- END LEFT HALF -->

                  <!-- RIGHT HALF -->
                  <div
                    role="tabpanel"
                    class="tab-pane"
                    v-bind:class="showRightHalf ? 'active' : ''"
                  >
                    <div class="top-list">
                      <div
                        class="add-toppings-str pl-10"
                        v-if="addRightToppingList && addRightToppingList.length"
                      >
                        <div class="text-green">
                          <strong
                            >{{ $t('cart.add') }}:
                            {{
                              convertString(
                                addRightToppingList
                                  .map(function(f) {
                                    return f.name
                                  })
                                  .join(', ')
                              )
                            }}
                          </strong>
                        </div>
                      </div>
                      <div
                        class="remove-toppings-str pl-10"
                        v-if="
                          removedRightToppingList &&
                            removedRightToppingList.length
                        "
                      >
                        <div class="text-orange">
                          <strong>
                            {{ $t('cart.remove') }}:
                            {{
                              convertString(
                                removedRightToppingList
                                  .map(function(f) {
                                    return f.name
                                  })
                                  .join(', ')
                              )
                            }}
                          </strong>
                        </div>
                      </div>
                    </div>

                    <div v-if="recipeList && recipeList.length">
                      <!-- RECIPE -->
                      <div v-show="showRightHalf && showRightHalfRecipe">
                        <div
                          v-if="recipeList && recipeList.length"
                          v-for="item in recipeList"
                          :key="RECIPE_RIGHT + genUniqueKey(item)"
                          @click="selectRecipe(item, RECIPE_RIGHT)"
                          class="variant-addon-item option display-flex t15 p10 text-black bg-grey-lightestest md:bg-transparent active"
                        >
                          <div
                            class="left flex1 display-flex items-center justify-start text-black fw400"
                          >
                            <img
                              class="option---icon"
                              v-if="item.image"
                              v-bind:alt="item.name"
                              v-bind:src="item.image.desktop_thumbnail"
                            />
                            <div class="pl-10 option---content ">
                              <div>{{ item.name }}</div>
                              <div class="option__long-description t13 fw400">
                                {{ item.main_description }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="price fw600 text-right pl10 pr10 flex-center"
                          >
                            {{
                              $t('currency_display', {
                                currency: $const.CURRENCY,
                                amount: formatNumDisplay(getRecipePrice(item))
                              })
                            }}
                          </div>
                        </div>
                      </div>
                      <!-- END RECIPE -->

                      <!-- TOPPING -->
                      <div v-show="showRightHalf && showRightHalfTopping">
                        <div class="variant-addon-choosen p10">
                          <div class="display-flex t15">
                            <span
                              class="flex1 name fw400 text-black"
                              v-if="selectedRightRecipe"
                            >
                              {{ selectedRightRecipe.name }}
                            </span>
                            <a
                              @click="changeRecipe(RECIPE_RIGHT)"
                              class="cursor-pointer cl-78838c border-b t13"
                              >{{ $t('button_change') }}</a
                            >
                          </div>
                        </div>

                        <div class="p10 box-topping-contain">
                          <div class="menu-toppings  ">
                            <div
                              class="topping-header cheese text-black "
                              style=""
                              v-if="showExtra"
                            >
                              {{ $t('change_cheese') }}
                            </div>
                            <div
                              class="extra-cheese option option-cheese bg-grey-lightestest md:bg-transparent"
                              v-bind:class="{
                                'opacity-3': checkDisableExtra(topping.uuid)
                              }"
                              style=""
                              v-for="topping in extraTopping"
                              :key="genUniqueKey(topping)"
                              v-if="topping.special === '1'"
                            >
                              <div class="xs-mt-20">
                                <div class="extra-cheese-option" style="">
                                  <label
                                    class="xs-items-center xs-px-10 rounded label-radio"
                                    :for="topping.uuid"
                                  >
                                    <input
                                      class="xs-block rounded xs-p-5 radio ExtraCheese"
                                      :id="topping.uuid"
                                      name="ExtraCheese"
                                      :value="topping"
                                      v-model="choosedCheese"
                                      type="checkbox"
                                      :disabled="
                                        checkDisableExtra(topping.uuid)
                                      "
                                    />
                                    <i class="pull-left xs-my-15"></i>
                                    <span
                                      class="xs-mr-auto typo-l5 xs-items-center xs-py-15"
                                    >
                                      <i class="xs-hide md-block xs-mr-10"></i>
                                      <span class="fw400 text-black t15">{{
                                        topping.name
                                      }}</span>
                                    </span>
                                    <span
                                      class="pull-right extra-cheese-price t15 fw600 cl-525252"
                                      >+
                                      {{
                                        $t('currency_display', {
                                          currency: $const.CURRENCY,
                                          amount: formatNumDisplay(
                                            getPriceTopping(topping)
                                          )
                                        })
                                      }}
                                    </span>
                                  </label>
                                </div>
                                <div class="xs-mb-10"></div>
                              </div>
                            </div>

                            <div
                              class="topping-header"
                              style=""
                              v-if="
                                rightToppingList &&
                                  rightToppingList.extra &&
                                  rightToppingList.extra.length
                              "
                              @click="
                                showRightToppingPlus = !showRightToppingPlus
                              "
                              :class="{ active: showRightToppingPlus }"
                            >
                              {{ $t('add_toppings') }}
                              <i data-v-38def44e="" class="fa fa-caret-up"></i>
                            </div>
                            <collapse v-model="showRightToppingPlus">
                              <div
                                class="add-toppings text-black"
                                style=""
                                v-if="
                                  rightToppingList &&
                                    rightToppingList.extra &&
                                    rightToppingList.extra.length
                                "
                              >
                                <div
                                  v-for="(extopping,
                                  index) in rightToppingList.extra"
                                  :key="RECIPE_RIGHT + genUniqueKey(extopping)"
                                  v-if="extopping.special != '1'"
                                  class="add-toppings-item display-flex t15 p10 cursor-pointer option bg-grey-lightestest md:bg-transparent"
                                  v-bind:class="{
                                    disable: extopping.isDisable
                                  }"
                                  @click="
                                    changeTopping(
                                      extopping,
                                      2,
                                      index,
                                      RECIPE_RIGHT
                                    )
                                  "
                                >
                                  <div class="flex1 fw400 ">
                                    <img
                                      v-bind:src="extopping.ImageURL"
                                      width="24"
                                      height="24"
                                    />
                                    <span class="pl-10">{{
                                      convertString(extopping.name)
                                    }}</span>
                                  </div>
                                  <div
                                    class="text-right pl10 pr10 topping-price fw600 cl-525252"
                                    style=""
                                    v-if="
                                      !extopping.default &&
                                        getPriceTopping(extopping)
                                    "
                                  >
                                    +
                                    {{
                                      $t('currency_display', {
                                        currency: $const.CURRENCY,
                                        amount: formatNumDisplay(
                                          getPriceTopping(extopping)
                                        )
                                      })
                                    }}
                                  </div>
                                  <div
                                    class="add-topping-btn bold text-right pl10 pr10"
                                  >
                                    <div
                                      class="add-icon mr-0 ml-5 fa-plus-icon "
                                    >
                                      <i class="add-icon__v"></i>
                                      <i class="add-icon__h"></i>
                                    </div>
                                    <img
                                      src="https://static.phdvasia.com/mx/icons/remove.svg"
                                      class="cursor-pointer fa-remove-image"
                                      alt="remove"
                                      style="display: none"
                                    />
                                  </div>
                                </div>
                              </div>
                            </collapse>

                            <div
                              class="topping-header"
                              style=""
                              v-if="
                                rightToppingList && rightToppingList.default
                              "
                              @click="
                                showRightToppingMinus = !showRightToppingMinus
                              "
                              :class="{ active: showRightToppingMinus }"
                            >
                              {{ $t('your_toppings') }}
                              <i data-v-38def44e="" class="fa fa-caret-up"></i>
                            </div>
                            <collapse v-model="showRightToppingMinus">
                              <div
                                class="your-toppings text-black"
                                style=""
                                v-if="
                                  rightToppingList &&
                                    rightToppingList.default &&
                                    rightToppingList.default.length
                                "
                              >
                                <div
                                  class="your-toppings-item display-flex t15 p10 cursor-pointer option visible"
                                  style=""
                                  v-for="(topping,
                                  index) in rightToppingList.default"
                                  :key="RECIPE_RIGHT + genUniqueKey(topping)"
                                  @click="
                                    changeTopping(
                                      topping,
                                      1,
                                      index,
                                      RECIPE_RIGHT
                                    )
                                  "
                                >
                                  <div class="flex1 fw400">
                                    <img
                                      v-bind:src="topping.ImageURL"
                                      width="24"
                                      height="2"
                                    />
                                    <span class="pl-10">{{
                                      convertString(topping.name)
                                    }}</span>
                                  </div>
                                  <div
                                    class="text-right pl10 pr10 topping-price fw600 cl-525252"
                                    style=""
                                    v-if="
                                      !topping.default &&
                                        getPriceTopping(topping)
                                    "
                                  >
                                    +
                                    {{
                                      $t('currency_display', {
                                        currency: $const.CURRENCY,
                                        amount: formatNumDisplay(
                                          getPriceTopping(topping)
                                        )
                                      })
                                    }}
                                  </div>
                                  <div
                                    class="add-topping-btn bold text-right pl10 pr10"
                                  >
                                    <img
                                      src="https://static.phdvasia.com/mx/icons/remove.svg"
                                      class="cursor-pointer"
                                      alt="remove"
                                    />
                                  </div>
                                </div>
                              </div>
                            </collapse>
                          </div>
                        </div>
                      </div>
                      <!-- END TOPPING -->
                    </div>
                    <div
                      v-else
                      class="empty_recipe"
                      v-html="
                        $t('message_empty_menu', {
                          first_layer: selectedFirstLayer.name,
                          second_layer: selectedSecondLayer.name
                        })
                      "
                    ></div>
                  </div>
                  <!-- END RIGHT HALF -->
                </div>
              </div>
            </transition>
            <!-- END RECIPE  & TOPPING -->
          </div>
          <div v-show="buttonAdd.visible" class="modal-action">
            <!--<button type="button" @click="gotoHalf(menu)" class="btn btn-lg btn-success full  js-action-btn-add btn-half" v-if="menu.piece ===$const.HALF_HALF_ID">{{$t('btn_hatf_halt')}}</button>-->
            <button
              type="button"
              class="btn btn-lg btn-success full  js-action-btn-add"
              @click="addToBasket()"
              v-bind:class="{ disabled: !buttonAdd.active }"
              v-if="
                firstLayers &&
                  firstLayers.length &&
                  secondLayers &&
                  secondLayers.length
              "
            >
              {{ buttonAdd.text }}
              <span>
                {{
                  $t('currency_display', {
                    currency: $const.CURRENCY,
                    amount: formatNumDisplay(totalPrice)
                  })
                }}
              </span>
            </button>
            <!--<button type="button" @click="addToBasket()"
                    class="btn btn-lg btn-success full  js-action-btn-add" v-else>{{$t('add_to_my_basket')}}
                <span>+{{$const.CURRENCY}} <span
                        class="total-price">{{ formatNum(menu.price) }}</span></span>
            </button>-->
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.add-toppings-str,
.remove-toppings-str {
  margin-bottom: 10px;
}

.top-list {
}

#modal-menu .modal-head {
  padding: 0px 10px !important;
}

.menu-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain !important;
}

.modal-half #form-add-menu {
  display: flex;
}

.modal-half #form-add-menu .modal-body {
  flex: 1 !important;
  padding-left: 0;
}

.modal-half .image-cover {
  position: relative !important;
  background: #f3f3f6;
  overflow: hidden;
  display: flex;
}

.modal-half .image-cover .image {
  object-fit: cover !important;
}

.modal-half .image-cover img {
  flex: 1;
  width: 50% !important;
  object-fit: cover;
}

.modal-half #modal-menu .variant-addon-item {
  cursor: pointer;
  padding: 15px;
}

.tab-content {
  margin-top: 10px;
}

.v--modal-overlay {
  overflow: hidden !important;
}

.v--modal-overlay .v--modal-box {
  overflow: hidden !important;
}

.selector-container {
  height: auto !important;
  overflow: scroll !important;
  max-height: calc(68vh - 0px) !important;
}

.tab {
  display: none;
  background-color: #f4f6f7;
  padding: 14px !important;
}

.tab.active {
  display: block;
  background: white !important;
}

.tab:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: auto 5px;
  vertical-align: text-top;
  filter: grayscale(100%) contrast(80%);
}

.tab.active:before {
  filter: inherit;
}

.tab-left:before {
  background: url(https://www.pizzahut.co.uk/order/routes/order/pizzas/pizza/hnh-left-half.fd0bf2a17cb4de1bf67996c8739e7aca.svg)
    no-repeat 50%;
  background-size: contain;
}

.tab-right:before {
  background: url(https://www.pizzahut.co.uk/order/routes/order/pizzas/pizza/hnh-left-half.fd0bf2a17cb4de1bf67996c8739e7aca.svg)
    no-repeat 50%;
  background-size: contain;
}

.tab-right.active:before {
}

.tab-nav {
  border-top: 1px solid #eeeeee;
}

.variant-addon-choosen {
  margin: 0px 0px;
}

.tab-nav {
  margin: 0px 10px;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block !important;
}

#recipe-list .left {
  align-items: start;
}

#recipe-list .price {
  align-items: start;
  margin-top: 0px !important;
  padding: 5px 0px;
  justify-content: flex-end;
}

#recipe-list .option---icon {
  width: 100px;
  height: auto !important;
  margin-right: 5px;
}

#recipe-list .option---content {
  flex: 1 auto !important;
  margin-top: -5px;
}

#recipe-list .option__long-description {
  line-height: 21px;
  font-size: 13px;
}

.add-topping-btn {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .v--modal .close-left,
  .modal .close-left {
    right: 15px;
    top: 15px;
    position: fixed !important;
  }

  .v--modal-box.v--modal {
    padding: 20px;
  }

  .v--modal-background-click {
    overflow: hidden !important;
  }

  .modal-half #form-add-menu {
    flex-direction: column;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    background: white;
  }

  .has-image {
    display: none;
  }

  .modal-half #form-add-menu {
    flex-direction: column;
  }

  .selector-container {
    max-height: calc(100%) !important;
  }

  .option__long-description {
    display: none;
  }

  #recipe-list .option---content {
    margin-top: 0;
  }

  #modal-menu .image-cover img {
    height: 130px;
    position: relative;
    left: inherit;
    top: inherit;
  }
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.btn-half {
  margin: 0px 0px 15px;
}
</style>
